import React from 'react';
import { Router } from '@reach/router';
import Layout from 'components/Layout';
import EventPage from '../components/Event';

const Event = () => {
  return (
    <Layout>
      <Router basepath="/event">
        <EventPage path="/:name/:id" />
      </Router>
    </Layout>
  );
};

export default Event;
