import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Router, navigate } from '@reach/router';
import tw from 'twin.macro';
import moment from 'moment';
import { decode } from 'js-base64';
import SocialLinkBar from 'components/SocialLinkBar';
import { getEvent } from 'services/hasura';
import EventAnimation from 'components/EventAnimation';
import Modal from 'components/Modal';
import { openInNewTab, whatsappShare } from 'utils/share';
import Seo from 'components/Seo';
import { dateInPast } from 'utils/date';
import { Box, Text, Button, Anchor } from 'grommet';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { EventPage } from 'tutadoo-design-system/src/components/pages/Event';
import { ProductPage } from 'tutadoo-design-system/src/components/pages/Product';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderApi } from 'services/api/order-api';
import { useApi } from 'providers/ApiProvider';
import { currencyToText } from 'utils/money';
import useDisclosure from 'tutadoo-design-system/src/hooks/useDisclosure';
import { ShippingDetailsDrawer } from 'tutadoo-design-system/src/components';

const Wrapper = tw(Box)`p-10`;
const Image = tw.img`w-full rounded-md border-2 object-cover mt-4 h-auto bg-scroll`;
const Content = styled.div`
  p:after {
    content: '';
    display: inline-block;
    width: 0px;
    /* outline: 1px solid red; debug, remove me */
  }
`;

export default function Event() {
  const { api } = useApi();
  const { getIdTokenClaims } = useAuth0();
  const [busy, setBusy] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { isOpen, onClose, onOpen } = useDisclosure({});
  const params = useParams();
  const resp: { data: namespace.Data; loading: boolean } = useQuery(getEvent(params.id));
  if (!resp.data && !params?.id) {
    navigate('', { replace: true });
    return <></>;
  }

  const onPay = async (data) => {
    const items = resp.data.event.ticket_types
      .map(({ id, name, price, currency }) => {
        const quantity = parseInt(data[`ticket.${id}`]);
        if (quantity > 0) {
          return {
            name,
            quantity,
            price,
            product: 'ticket',
            product_id: id,
            currency: currencyToText[currency !== undefined ? currency : 'KES']
          };
        }
        return null;
      })
      .filter((v) => v !== null);

    if (items.length > 0) {
      setSelectedItems(items);
      onOpen();
    } else {
      window.alert('Please choose a quantity');
    }
  };
  const onBuyNow = async (e) => {
    if (busy) return;
    const { event } = resp.data;
    const items = selectedItems;
    if (items.length > 0) {
      const total = items.reduce(
        (t, { price, quantity }) => {
          return { price: price * quantity + t.price, quantity: t.quantity + quantity };
        },
        { price: 0, quantity: 0 }
      );
      //   TODO: hack
      total.subtotal = total.price;
      total.taxes = 0;
      total.total = total.price;
      const claims = await getIdTokenClaims();
      const orderAPI = new OrderApi(api, claims ? claims.__raw : null);
      setBusy(true);
      const { firstName, lastName, phone, email, ...questions } = e;
      const mergedData = {
        ...total,
        firstName,
        phone,
        lastName,
        email,
        questions,
        day: moment(new Date()).format(),
        days: [moment(new Date()).format()],
        venue: event.venue,
        event,
        items
      };
      const result = await orderAPI.createOrder(mergedData);
      if (result.kind === 'bad-data') {
        if ((result.data[0].message as string).includes('Out of stock')) {
          alert(`Failed: ${result.data[0].message}`);
        } else {
          alert('Failed: Please try again');
        }
      } else if (total.price > 0) {
        navigate(`/booking/choose-payment/?id=${result.order.insert_orders_one.id}`, {
          replace: true
        });
      } else {
        navigate(`/booking/complete/?id=${result.order.insert_orders_one.id}`, {
          replace: true
        });
      }
    } else {
      window.alert('Please choose a quantity');
    }
    setBusy(false);
  };
  return (
    <>
      {resp.loading && resp.data === undefined ? (
        <Wrapper>
          <EventAnimation />
        </Wrapper>
      ) : (
        <>
          <div className="w-full lg:w-10/12 mx-auto">
            {resp.data.event.event_type === 'Book' ? (
              <ProductPage
                busy={busy}
                event={resp.data.event}
                basePath=""
                currency="KES"
                onBuyNow={onPay}
                // getTicketsURL={`/booking/checkout/?id=${resp.data.event.id}`}
              />
            ) : (
              <EventPage
                event={resp.data.event}
                basePath=""
                getTicketsURL={`/booking/checkout/?id=${resp.data.event.id}`}
              />
            )}
          </div>

          {isOpen && (
            <ShippingDetailsDrawer
              isOpen={isOpen}
              onClose={onClose}
              onSubmit={onBuyNow}
              initialValues={{}}
            />
          )}
        </>
      )}
    </>
  );
}
